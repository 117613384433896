.matrix .tiny-span-auto { width: auto; }
.matrix .tiny-span-0 { width: 0; }
.matrix .tiny-skip-0 { margin-left: 0; }
.matrix .tiny-span-1 { width: 100%; }
.matrix .tiny-skip-1 { margin-left: 100%; }
.matrix .tiny-fill { width: 100%; }
.matrix .tiny-span-1-2 { width: 50%; }
.matrix .tiny-skip-1-2 { margin-left: 50%; }
.matrix .tiny-span-1-3 { width: 33.33333333%; }
.matrix .tiny-skip-1-3 { margin-left: 33.33333333%; }
.matrix .tiny-span-2-3 { width: 66.66666667%; }
.matrix .tiny-skip-2-3 { margin-left: 66.66666667%; }
.matrix .tiny-span-1-4 { width: 25%; }
.matrix .tiny-skip-1-4 { margin-left: 25%; }
.matrix .tiny-span-2-4 { width: 50%; }
.matrix .tiny-skip-2-4 { margin-left: 50%; }
.matrix .tiny-span-3-4 { width: 75%; }
.matrix .tiny-skip-3-4 { margin-left: 75%; }
.matrix .tiny-span-1-5 { width: 20%; }
.matrix .tiny-skip-1-5 { margin-left: 20%; }
.matrix .tiny-span-2-5 { width: 40%; }
.matrix .tiny-skip-2-5 { margin-left: 40%; }
.matrix .tiny-span-3-5 { width: 60%; }
.matrix .tiny-skip-3-5 { margin-left: 60%; }
.matrix .tiny-span-4-5 { width: 80%; }
.matrix .tiny-skip-4-5 { margin-left: 80%; }
.matrix .tiny-span-1-6 { width: 16.66666667%; }
.matrix .tiny-skip-1-6 { margin-left: 16.66666667%; }
.matrix .tiny-span-2-6 { width: 33.33333333%; }
.matrix .tiny-skip-2-6 { margin-left: 33.33333333%; }
.matrix .tiny-span-3-6 { width: 50%; }
.matrix .tiny-skip-3-6 { margin-left: 50%; }
.matrix .tiny-span-4-6 { width: 66.66666667%; }
.matrix .tiny-skip-4-6 { margin-left: 66.66666667%; }
.matrix .tiny-span-5-6 { width: 83.33333333%; }
.matrix .tiny-skip-5-6 { margin-left: 83.33333333%; }
.matrix .tiny-span-1-7 { width: 14.28571429%; }
.matrix .tiny-skip-1-7 { margin-left: 14.28571429%; }
.matrix .tiny-span-2-7 { width: 28.57142857%; }
.matrix .tiny-skip-2-7 { margin-left: 28.57142857%; }
.matrix .tiny-span-3-7 { width: 42.85714286%; }
.matrix .tiny-skip-3-7 { margin-left: 42.85714286%; }
.matrix .tiny-span-4-7 { width: 57.14285714%; }
.matrix .tiny-skip-4-7 { margin-left: 57.14285714%; }
.matrix .tiny-span-5-7 { width: 71.42857143%; }
.matrix .tiny-skip-5-7 { margin-left: 71.42857143%; }
.matrix .tiny-span-6-7 { width: 85.71428571%; }
.matrix .tiny-skip-6-7 { margin-left: 85.71428571%; }
.matrix .tiny-span-1-8 { width: 12.5%; }
.matrix .tiny-skip-1-8 { margin-left: 12.5%; }
.matrix .tiny-span-2-8 { width: 25%; }
.matrix .tiny-skip-2-8 { margin-left: 25%; }
.matrix .tiny-span-3-8 { width: 37.5%; }
.matrix .tiny-skip-3-8 { margin-left: 37.5%; }
.matrix .tiny-span-4-8 { width: 50%; }
.matrix .tiny-skip-4-8 { margin-left: 50%; }
.matrix .tiny-span-5-8 { width: 62.5%; }
.matrix .tiny-skip-5-8 { margin-left: 62.5%; }
.matrix .tiny-span-6-8 { width: 75%; }
.matrix .tiny-skip-6-8 { margin-left: 75%; }
.matrix .tiny-span-7-8 { width: 87.5%; }
.matrix .tiny-skip-7-8 { margin-left: 87.5%; }
.matrix .tiny-span-1-9 { width: 11.11111111%; }
.matrix .tiny-skip-1-9 { margin-left: 11.11111111%; }
.matrix .tiny-span-2-9 { width: 22.22222222%; }
.matrix .tiny-skip-2-9 { margin-left: 22.22222222%; }
.matrix .tiny-span-3-9 { width: 33.33333333%; }
.matrix .tiny-skip-3-9 { margin-left: 33.33333333%; }
.matrix .tiny-span-4-9 { width: 44.44444444%; }
.matrix .tiny-skip-4-9 { margin-left: 44.44444444%; }
.matrix .tiny-span-5-9 { width: 55.55555556%; }
.matrix .tiny-skip-5-9 { margin-left: 55.55555556%; }
.matrix .tiny-span-6-9 { width: 66.66666667%; }
.matrix .tiny-skip-6-9 { margin-left: 66.66666667%; }
.matrix .tiny-span-7-9 { width: 77.77777778%; }
.matrix .tiny-skip-7-9 { margin-left: 77.77777778%; }
.matrix .tiny-span-8-9 { width: 88.88888889%; }
.matrix .tiny-skip-8-9 { margin-left: 88.88888889%; }
.matrix .tiny-span-1-10 { width: 10%; }
.matrix .tiny-skip-1-10 { margin-left: 10%; }
.matrix .tiny-span-2-10 { width: 20%; }
.matrix .tiny-skip-2-10 { margin-left: 20%; }
.matrix .tiny-span-3-10 { width: 30%; }
.matrix .tiny-skip-3-10 { margin-left: 30%; }
.matrix .tiny-span-4-10 { width: 40%; }
.matrix .tiny-skip-4-10 { margin-left: 40%; }
.matrix .tiny-span-5-10 { width: 50%; }
.matrix .tiny-skip-5-10 { margin-left: 50%; }
.matrix .tiny-span-6-10 { width: 60%; }
.matrix .tiny-skip-6-10 { margin-left: 60%; }
.matrix .tiny-span-7-10 { width: 70%; }
.matrix .tiny-skip-7-10 { margin-left: 70%; }
.matrix .tiny-span-8-10 { width: 80%; }
.matrix .tiny-skip-8-10 { margin-left: 80%; }
.matrix .tiny-span-9-10 { width: 90%; }
.matrix .tiny-skip-9-10 { margin-left: 90%; }
.matrix .tiny-span-1-11 { width: 9.09090909%; }
.matrix .tiny-skip-1-11 { margin-left: 9.09090909%; }
.matrix .tiny-span-2-11 { width: 18.18181818%; }
.matrix .tiny-skip-2-11 { margin-left: 18.18181818%; }
.matrix .tiny-span-3-11 { width: 27.27272727%; }
.matrix .tiny-skip-3-11 { margin-left: 27.27272727%; }
.matrix .tiny-span-4-11 { width: 36.36363636%; }
.matrix .tiny-skip-4-11 { margin-left: 36.36363636%; }
.matrix .tiny-span-5-11 { width: 45.45454545%; }
.matrix .tiny-skip-5-11 { margin-left: 45.45454545%; }
.matrix .tiny-span-6-11 { width: 54.54545455%; }
.matrix .tiny-skip-6-11 { margin-left: 54.54545455%; }
.matrix .tiny-span-7-11 { width: 63.63636364%; }
.matrix .tiny-skip-7-11 { margin-left: 63.63636364%; }
.matrix .tiny-span-8-11 { width: 72.72727273%; }
.matrix .tiny-skip-8-11 { margin-left: 72.72727273%; }
.matrix .tiny-span-9-11 { width: 81.81818182%; }
.matrix .tiny-skip-9-11 { margin-left: 81.81818182%; }
.matrix .tiny-span-10-11 { width: 90.90909091%; }
.matrix .tiny-skip-10-11 { margin-left: 90.90909091%; }
.matrix .tiny-span-1-12 { width: 8.33333333%; }
.matrix .tiny-skip-1-12 { margin-left: 8.33333333%; }
.matrix .tiny-span-2-12 { width: 16.66666667%; }
.matrix .tiny-skip-2-12 { margin-left: 16.66666667%; }
.matrix .tiny-span-3-12 { width: 25%; }
.matrix .tiny-skip-3-12 { margin-left: 25%; }
.matrix .tiny-span-4-12 { width: 33.33333333%; }
.matrix .tiny-skip-4-12 { margin-left: 33.33333333%; }
.matrix .tiny-span-5-12 { width: 41.66666667%; }
.matrix .tiny-skip-5-12 { margin-left: 41.66666667%; }
.matrix .tiny-span-6-12 { width: 50%; }
.matrix .tiny-skip-6-12 { margin-left: 50%; }
.matrix .tiny-span-7-12 { width: 58.33333333%; }
.matrix .tiny-skip-7-12 { margin-left: 58.33333333%; }
.matrix .tiny-span-8-12 { width: 66.66666667%; }
.matrix .tiny-skip-8-12 { margin-left: 66.66666667%; }
.matrix .tiny-span-9-12 { width: 75%; }
.matrix .tiny-skip-9-12 { margin-left: 75%; }
.matrix .tiny-span-10-12 { width: 83.33333333%; }
.matrix .tiny-skip-10-12 { margin-left: 83.33333333%; }
.matrix .tiny-span-11-12 { width: 91.66666667%; }
.matrix .tiny-skip-11-12 { margin-left: 91.66666667%; }
.matrix .tiny-align-left { text-align: left; }
.matrix .tiny-align-center { text-align: center; }
.matrix .tiny-align-right { text-align: right; }
.matrix .tiny-hidden { display: none; }
.matrix .tiny-display-none { display: none; }
.matrix .tiny-display-block { display: block; }
.matrix .tiny-display-inline { display: inline; }
.matrix .tiny-display-inline-block { display: inline-block; }
.matrix .tiny-shown { display: block; }
.matrix .tiny-visible { display: block; }
.matrix .tiny-inline { display: inline; }
.matrix .tiny-inline-block { display: inline-block; }
.matrix .tiny-float-left { float: left; }
.matrix .tiny-float-right { float: right; }
.matrix .tiny-float-none { float: none; }
.matrix .tiny-unpad-left[class*='span-'] { padding-left: 0; }
.matrix .tiny-unpad-right[class*='span-'] { padding-right: 0; }

.matrix .tiny-unpad-both[class*='span-'] {
  padding-left: 0;
  padding-right: 0;
}

.matrix .tiny-pad-left[class*='span-'] { padding-left: 10px; }
.matrix .tiny-pad-right[class*='span-'] { padding-right: 10px; }

.matrix .tiny-pad-both[class*='span-'] {
  padding-left: 10px;
  padding-right: 10px;
}

.matrix .tiny-reverse[class*='span-'] { float: right; }
.matrix .tiny-unreverse[class*='span-'] { float: left; }
.matrix .xs-span-auto { width: auto; }
.matrix .xs-span-0 { width: 0; }
.matrix .xs-skip-0 { margin-left: 0; }
.matrix .xs-span-1 { width: 100%; }
.matrix .xs-skip-1 { margin-left: 100%; }
.matrix .xs-fill { width: 100%; }
.matrix .xs-span-1-2 { width: 50%; }
.matrix .xs-skip-1-2 { margin-left: 50%; }
.matrix .xs-span-1-3 { width: 33.33333333%; }
.matrix .xs-skip-1-3 { margin-left: 33.33333333%; }
.matrix .xs-span-2-3 { width: 66.66666667%; }
.matrix .xs-skip-2-3 { margin-left: 66.66666667%; }
.matrix .xs-span-1-4 { width: 25%; }
.matrix .xs-skip-1-4 { margin-left: 25%; }
.matrix .xs-span-2-4 { width: 50%; }
.matrix .xs-skip-2-4 { margin-left: 50%; }
.matrix .xs-span-3-4 { width: 75%; }
.matrix .xs-skip-3-4 { margin-left: 75%; }
.matrix .xs-span-1-5 { width: 20%; }
.matrix .xs-skip-1-5 { margin-left: 20%; }
.matrix .xs-span-2-5 { width: 40%; }
.matrix .xs-skip-2-5 { margin-left: 40%; }
.matrix .xs-span-3-5 { width: 60%; }
.matrix .xs-skip-3-5 { margin-left: 60%; }
.matrix .xs-span-4-5 { width: 80%; }
.matrix .xs-skip-4-5 { margin-left: 80%; }
.matrix .xs-span-1-6 { width: 16.66666667%; }
.matrix .xs-skip-1-6 { margin-left: 16.66666667%; }
.matrix .xs-span-2-6 { width: 33.33333333%; }
.matrix .xs-skip-2-6 { margin-left: 33.33333333%; }
.matrix .xs-span-3-6 { width: 50%; }
.matrix .xs-skip-3-6 { margin-left: 50%; }
.matrix .xs-span-4-6 { width: 66.66666667%; }
.matrix .xs-skip-4-6 { margin-left: 66.66666667%; }
.matrix .xs-span-5-6 { width: 83.33333333%; }
.matrix .xs-skip-5-6 { margin-left: 83.33333333%; }
.matrix .xs-span-1-7 { width: 14.28571429%; }
.matrix .xs-skip-1-7 { margin-left: 14.28571429%; }
.matrix .xs-span-2-7 { width: 28.57142857%; }
.matrix .xs-skip-2-7 { margin-left: 28.57142857%; }
.matrix .xs-span-3-7 { width: 42.85714286%; }
.matrix .xs-skip-3-7 { margin-left: 42.85714286%; }
.matrix .xs-span-4-7 { width: 57.14285714%; }
.matrix .xs-skip-4-7 { margin-left: 57.14285714%; }
.matrix .xs-span-5-7 { width: 71.42857143%; }
.matrix .xs-skip-5-7 { margin-left: 71.42857143%; }
.matrix .xs-span-6-7 { width: 85.71428571%; }
.matrix .xs-skip-6-7 { margin-left: 85.71428571%; }
.matrix .xs-span-1-8 { width: 12.5%; }
.matrix .xs-skip-1-8 { margin-left: 12.5%; }
.matrix .xs-span-2-8 { width: 25%; }
.matrix .xs-skip-2-8 { margin-left: 25%; }
.matrix .xs-span-3-8 { width: 37.5%; }
.matrix .xs-skip-3-8 { margin-left: 37.5%; }
.matrix .xs-span-4-8 { width: 50%; }
.matrix .xs-skip-4-8 { margin-left: 50%; }
.matrix .xs-span-5-8 { width: 62.5%; }
.matrix .xs-skip-5-8 { margin-left: 62.5%; }
.matrix .xs-span-6-8 { width: 75%; }
.matrix .xs-skip-6-8 { margin-left: 75%; }
.matrix .xs-span-7-8 { width: 87.5%; }
.matrix .xs-skip-7-8 { margin-left: 87.5%; }
.matrix .xs-span-1-9 { width: 11.11111111%; }
.matrix .xs-skip-1-9 { margin-left: 11.11111111%; }
.matrix .xs-span-2-9 { width: 22.22222222%; }
.matrix .xs-skip-2-9 { margin-left: 22.22222222%; }
.matrix .xs-span-3-9 { width: 33.33333333%; }
.matrix .xs-skip-3-9 { margin-left: 33.33333333%; }
.matrix .xs-span-4-9 { width: 44.44444444%; }
.matrix .xs-skip-4-9 { margin-left: 44.44444444%; }
.matrix .xs-span-5-9 { width: 55.55555556%; }
.matrix .xs-skip-5-9 { margin-left: 55.55555556%; }
.matrix .xs-span-6-9 { width: 66.66666667%; }
.matrix .xs-skip-6-9 { margin-left: 66.66666667%; }
.matrix .xs-span-7-9 { width: 77.77777778%; }
.matrix .xs-skip-7-9 { margin-left: 77.77777778%; }
.matrix .xs-span-8-9 { width: 88.88888889%; }
.matrix .xs-skip-8-9 { margin-left: 88.88888889%; }
.matrix .xs-span-1-10 { width: 10%; }
.matrix .xs-skip-1-10 { margin-left: 10%; }
.matrix .xs-span-2-10 { width: 20%; }
.matrix .xs-skip-2-10 { margin-left: 20%; }
.matrix .xs-span-3-10 { width: 30%; }
.matrix .xs-skip-3-10 { margin-left: 30%; }
.matrix .xs-span-4-10 { width: 40%; }
.matrix .xs-skip-4-10 { margin-left: 40%; }
.matrix .xs-span-5-10 { width: 50%; }
.matrix .xs-skip-5-10 { margin-left: 50%; }
.matrix .xs-span-6-10 { width: 60%; }
.matrix .xs-skip-6-10 { margin-left: 60%; }
.matrix .xs-span-7-10 { width: 70%; }
.matrix .xs-skip-7-10 { margin-left: 70%; }
.matrix .xs-span-8-10 { width: 80%; }
.matrix .xs-skip-8-10 { margin-left: 80%; }
.matrix .xs-span-9-10 { width: 90%; }
.matrix .xs-skip-9-10 { margin-left: 90%; }
.matrix .xs-span-1-11 { width: 9.09090909%; }
.matrix .xs-skip-1-11 { margin-left: 9.09090909%; }
.matrix .xs-span-2-11 { width: 18.18181818%; }
.matrix .xs-skip-2-11 { margin-left: 18.18181818%; }
.matrix .xs-span-3-11 { width: 27.27272727%; }
.matrix .xs-skip-3-11 { margin-left: 27.27272727%; }
.matrix .xs-span-4-11 { width: 36.36363636%; }
.matrix .xs-skip-4-11 { margin-left: 36.36363636%; }
.matrix .xs-span-5-11 { width: 45.45454545%; }
.matrix .xs-skip-5-11 { margin-left: 45.45454545%; }
.matrix .xs-span-6-11 { width: 54.54545455%; }
.matrix .xs-skip-6-11 { margin-left: 54.54545455%; }
.matrix .xs-span-7-11 { width: 63.63636364%; }
.matrix .xs-skip-7-11 { margin-left: 63.63636364%; }
.matrix .xs-span-8-11 { width: 72.72727273%; }
.matrix .xs-skip-8-11 { margin-left: 72.72727273%; }
.matrix .xs-span-9-11 { width: 81.81818182%; }
.matrix .xs-skip-9-11 { margin-left: 81.81818182%; }
.matrix .xs-span-10-11 { width: 90.90909091%; }
.matrix .xs-skip-10-11 { margin-left: 90.90909091%; }
.matrix .xs-span-1-12 { width: 8.33333333%; }
.matrix .xs-skip-1-12 { margin-left: 8.33333333%; }
.matrix .xs-span-2-12 { width: 16.66666667%; }
.matrix .xs-skip-2-12 { margin-left: 16.66666667%; }
.matrix .xs-span-3-12 { width: 25%; }
.matrix .xs-skip-3-12 { margin-left: 25%; }
.matrix .xs-span-4-12 { width: 33.33333333%; }
.matrix .xs-skip-4-12 { margin-left: 33.33333333%; }
.matrix .xs-span-5-12 { width: 41.66666667%; }
.matrix .xs-skip-5-12 { margin-left: 41.66666667%; }
.matrix .xs-span-6-12 { width: 50%; }
.matrix .xs-skip-6-12 { margin-left: 50%; }
.matrix .xs-span-7-12 { width: 58.33333333%; }
.matrix .xs-skip-7-12 { margin-left: 58.33333333%; }
.matrix .xs-span-8-12 { width: 66.66666667%; }
.matrix .xs-skip-8-12 { margin-left: 66.66666667%; }
.matrix .xs-span-9-12 { width: 75%; }
.matrix .xs-skip-9-12 { margin-left: 75%; }
.matrix .xs-span-10-12 { width: 83.33333333%; }
.matrix .xs-skip-10-12 { margin-left: 83.33333333%; }
.matrix .xs-span-11-12 { width: 91.66666667%; }
.matrix .xs-skip-11-12 { margin-left: 91.66666667%; }
.matrix .xs-align-left { text-align: left; }
.matrix .xs-align-center { text-align: center; }
.matrix .xs-align-right { text-align: right; }
.matrix .xs-hidden { display: none; }
.matrix .xs-display-none { display: none; }
.matrix .xs-display-block { display: block; }
.matrix .xs-display-inline { display: inline; }
.matrix .xs-display-inline-block { display: inline-block; }
.matrix .xs-shown { display: block; }
.matrix .xs-visible { display: block; }
.matrix .xs-inline { display: inline; }
.matrix .xs-inline-block { display: inline-block; }
.matrix .xs-float-left { float: left; }
.matrix .xs-float-right { float: right; }
.matrix .xs-float-none { float: none; }
.matrix .xs-unpad-left[class*='span-'] { padding-left: 0; }
.matrix .xs-unpad-right[class*='span-'] { padding-right: 0; }

.matrix .xs-unpad-both[class*='span-'] {
  padding-left: 0;
  padding-right: 0;
}

.matrix .xs-pad-left[class*='span-'] { padding-left: 10px; }
.matrix .xs-pad-right[class*='span-'] { padding-right: 10px; }

.matrix .xs-pad-both[class*='span-'] {
  padding-left: 10px;
  padding-right: 10px;
}

.matrix .xs-reverse[class*='span-'] { float: right; }
.matrix .xs-unreverse[class*='span-'] { float: left; }
.matrix .small-span-auto { width: auto; }
.matrix .small-span-0 { width: 0; }
.matrix .small-skip-0 { margin-left: 0; }
.matrix .small-span-1 { width: 100%; }
.matrix .small-skip-1 { margin-left: 100%; }
.matrix .small-fill { width: 100%; }
.matrix .small-span-1-2 { width: 50%; }
.matrix .small-skip-1-2 { margin-left: 50%; }
.matrix .small-span-1-3 { width: 33.33333333%; }
.matrix .small-skip-1-3 { margin-left: 33.33333333%; }
.matrix .small-span-2-3 { width: 66.66666667%; }
.matrix .small-skip-2-3 { margin-left: 66.66666667%; }
.matrix .small-span-1-4 { width: 25%; }
.matrix .small-skip-1-4 { margin-left: 25%; }
.matrix .small-span-2-4 { width: 50%; }
.matrix .small-skip-2-4 { margin-left: 50%; }
.matrix .small-span-3-4 { width: 75%; }
.matrix .small-skip-3-4 { margin-left: 75%; }
.matrix .small-span-1-5 { width: 20%; }
.matrix .small-skip-1-5 { margin-left: 20%; }
.matrix .small-span-2-5 { width: 40%; }
.matrix .small-skip-2-5 { margin-left: 40%; }
.matrix .small-span-3-5 { width: 60%; }
.matrix .small-skip-3-5 { margin-left: 60%; }
.matrix .small-span-4-5 { width: 80%; }
.matrix .small-skip-4-5 { margin-left: 80%; }
.matrix .small-span-1-6 { width: 16.66666667%; }
.matrix .small-skip-1-6 { margin-left: 16.66666667%; }
.matrix .small-span-2-6 { width: 33.33333333%; }
.matrix .small-skip-2-6 { margin-left: 33.33333333%; }
.matrix .small-span-3-6 { width: 50%; }
.matrix .small-skip-3-6 { margin-left: 50%; }
.matrix .small-span-4-6 { width: 66.66666667%; }
.matrix .small-skip-4-6 { margin-left: 66.66666667%; }
.matrix .small-span-5-6 { width: 83.33333333%; }
.matrix .small-skip-5-6 { margin-left: 83.33333333%; }
.matrix .small-span-1-7 { width: 14.28571429%; }
.matrix .small-skip-1-7 { margin-left: 14.28571429%; }
.matrix .small-span-2-7 { width: 28.57142857%; }
.matrix .small-skip-2-7 { margin-left: 28.57142857%; }
.matrix .small-span-3-7 { width: 42.85714286%; }
.matrix .small-skip-3-7 { margin-left: 42.85714286%; }
.matrix .small-span-4-7 { width: 57.14285714%; }
.matrix .small-skip-4-7 { margin-left: 57.14285714%; }
.matrix .small-span-5-7 { width: 71.42857143%; }
.matrix .small-skip-5-7 { margin-left: 71.42857143%; }
.matrix .small-span-6-7 { width: 85.71428571%; }
.matrix .small-skip-6-7 { margin-left: 85.71428571%; }
.matrix .small-span-1-8 { width: 12.5%; }
.matrix .small-skip-1-8 { margin-left: 12.5%; }
.matrix .small-span-2-8 { width: 25%; }
.matrix .small-skip-2-8 { margin-left: 25%; }
.matrix .small-span-3-8 { width: 37.5%; }
.matrix .small-skip-3-8 { margin-left: 37.5%; }
.matrix .small-span-4-8 { width: 50%; }
.matrix .small-skip-4-8 { margin-left: 50%; }
.matrix .small-span-5-8 { width: 62.5%; }
.matrix .small-skip-5-8 { margin-left: 62.5%; }
.matrix .small-span-6-8 { width: 75%; }
.matrix .small-skip-6-8 { margin-left: 75%; }
.matrix .small-span-7-8 { width: 87.5%; }
.matrix .small-skip-7-8 { margin-left: 87.5%; }
.matrix .small-span-1-9 { width: 11.11111111%; }
.matrix .small-skip-1-9 { margin-left: 11.11111111%; }
.matrix .small-span-2-9 { width: 22.22222222%; }
.matrix .small-skip-2-9 { margin-left: 22.22222222%; }
.matrix .small-span-3-9 { width: 33.33333333%; }
.matrix .small-skip-3-9 { margin-left: 33.33333333%; }
.matrix .small-span-4-9 { width: 44.44444444%; }
.matrix .small-skip-4-9 { margin-left: 44.44444444%; }
.matrix .small-span-5-9 { width: 55.55555556%; }
.matrix .small-skip-5-9 { margin-left: 55.55555556%; }
.matrix .small-span-6-9 { width: 66.66666667%; }
.matrix .small-skip-6-9 { margin-left: 66.66666667%; }
.matrix .small-span-7-9 { width: 77.77777778%; }
.matrix .small-skip-7-9 { margin-left: 77.77777778%; }
.matrix .small-span-8-9 { width: 88.88888889%; }
.matrix .small-skip-8-9 { margin-left: 88.88888889%; }
.matrix .small-span-1-10 { width: 10%; }
.matrix .small-skip-1-10 { margin-left: 10%; }
.matrix .small-span-2-10 { width: 20%; }
.matrix .small-skip-2-10 { margin-left: 20%; }
.matrix .small-span-3-10 { width: 30%; }
.matrix .small-skip-3-10 { margin-left: 30%; }
.matrix .small-span-4-10 { width: 40%; }
.matrix .small-skip-4-10 { margin-left: 40%; }
.matrix .small-span-5-10 { width: 50%; }
.matrix .small-skip-5-10 { margin-left: 50%; }
.matrix .small-span-6-10 { width: 60%; }
.matrix .small-skip-6-10 { margin-left: 60%; }
.matrix .small-span-7-10 { width: 70%; }
.matrix .small-skip-7-10 { margin-left: 70%; }
.matrix .small-span-8-10 { width: 80%; }
.matrix .small-skip-8-10 { margin-left: 80%; }
.matrix .small-span-9-10 { width: 90%; }
.matrix .small-skip-9-10 { margin-left: 90%; }
.matrix .small-span-1-11 { width: 9.09090909%; }
.matrix .small-skip-1-11 { margin-left: 9.09090909%; }
.matrix .small-span-2-11 { width: 18.18181818%; }
.matrix .small-skip-2-11 { margin-left: 18.18181818%; }
.matrix .small-span-3-11 { width: 27.27272727%; }
.matrix .small-skip-3-11 { margin-left: 27.27272727%; }
.matrix .small-span-4-11 { width: 36.36363636%; }
.matrix .small-skip-4-11 { margin-left: 36.36363636%; }
.matrix .small-span-5-11 { width: 45.45454545%; }
.matrix .small-skip-5-11 { margin-left: 45.45454545%; }
.matrix .small-span-6-11 { width: 54.54545455%; }
.matrix .small-skip-6-11 { margin-left: 54.54545455%; }
.matrix .small-span-7-11 { width: 63.63636364%; }
.matrix .small-skip-7-11 { margin-left: 63.63636364%; }
.matrix .small-span-8-11 { width: 72.72727273%; }
.matrix .small-skip-8-11 { margin-left: 72.72727273%; }
.matrix .small-span-9-11 { width: 81.81818182%; }
.matrix .small-skip-9-11 { margin-left: 81.81818182%; }
.matrix .small-span-10-11 { width: 90.90909091%; }
.matrix .small-skip-10-11 { margin-left: 90.90909091%; }
.matrix .small-span-1-12 { width: 8.33333333%; }
.matrix .small-skip-1-12 { margin-left: 8.33333333%; }
.matrix .small-span-2-12 { width: 16.66666667%; }
.matrix .small-skip-2-12 { margin-left: 16.66666667%; }
.matrix .small-span-3-12 { width: 25%; }
.matrix .small-skip-3-12 { margin-left: 25%; }
.matrix .small-span-4-12 { width: 33.33333333%; }
.matrix .small-skip-4-12 { margin-left: 33.33333333%; }
.matrix .small-span-5-12 { width: 41.66666667%; }
.matrix .small-skip-5-12 { margin-left: 41.66666667%; }
.matrix .small-span-6-12 { width: 50%; }
.matrix .small-skip-6-12 { margin-left: 50%; }
.matrix .small-span-7-12 { width: 58.33333333%; }
.matrix .small-skip-7-12 { margin-left: 58.33333333%; }
.matrix .small-span-8-12 { width: 66.66666667%; }
.matrix .small-skip-8-12 { margin-left: 66.66666667%; }
.matrix .small-span-9-12 { width: 75%; }
.matrix .small-skip-9-12 { margin-left: 75%; }
.matrix .small-span-10-12 { width: 83.33333333%; }
.matrix .small-skip-10-12 { margin-left: 83.33333333%; }
.matrix .small-span-11-12 { width: 91.66666667%; }
.matrix .small-skip-11-12 { margin-left: 91.66666667%; }
.matrix .small-align-left { text-align: left; }
.matrix .small-align-center { text-align: center; }
.matrix .small-align-right { text-align: right; }
.matrix .small-hidden { display: none; }
.matrix .small-display-none { display: none; }
.matrix .small-display-block { display: block; }
.matrix .small-display-inline { display: inline; }
.matrix .small-display-inline-block { display: inline-block; }
.matrix .small-shown { display: block; }
.matrix .small-visible { display: block; }
.matrix .small-inline { display: inline; }
.matrix .small-inline-block { display: inline-block; }
.matrix .small-float-left { float: left; }
.matrix .small-float-right { float: right; }
.matrix .small-float-none { float: none; }
.matrix .small-unpad-left[class*='span-'] { padding-left: 0; }
.matrix .small-unpad-right[class*='span-'] { padding-right: 0; }

.matrix .small-unpad-both[class*='span-'] {
  padding-left: 0;
  padding-right: 0;
}

.matrix .small-pad-left[class*='span-'] { padding-left: 10px; }
.matrix .small-pad-right[class*='span-'] { padding-right: 10px; }

.matrix .small-pad-both[class*='span-'] {
  padding-left: 10px;
  padding-right: 10px;
}

.matrix .small-reverse[class*='span-'] { float: right; }
.matrix .small-unreverse[class*='span-'] { float: left; }
.matrix .medium-span-auto { width: auto; }
.matrix .medium-span-0 { width: 0; }
.matrix .medium-skip-0 { margin-left: 0; }
.matrix .medium-span-1 { width: 100%; }
.matrix .medium-skip-1 { margin-left: 100%; }
.matrix .medium-fill { width: 100%; }
.matrix .medium-span-1-2 { width: 50%; }
.matrix .medium-skip-1-2 { margin-left: 50%; }
.matrix .medium-span-1-3 { width: 33.33333333%; }
.matrix .medium-skip-1-3 { margin-left: 33.33333333%; }
.matrix .medium-span-2-3 { width: 66.66666667%; }
.matrix .medium-skip-2-3 { margin-left: 66.66666667%; }
.matrix .medium-span-1-4 { width: 25%; }
.matrix .medium-skip-1-4 { margin-left: 25%; }
.matrix .medium-span-2-4 { width: 50%; }
.matrix .medium-skip-2-4 { margin-left: 50%; }
.matrix .medium-span-3-4 { width: 75%; }
.matrix .medium-skip-3-4 { margin-left: 75%; }
.matrix .medium-span-1-5 { width: 20%; }
.matrix .medium-skip-1-5 { margin-left: 20%; }
.matrix .medium-span-2-5 { width: 40%; }
.matrix .medium-skip-2-5 { margin-left: 40%; }
.matrix .medium-span-3-5 { width: 60%; }
.matrix .medium-skip-3-5 { margin-left: 60%; }
.matrix .medium-span-4-5 { width: 80%; }
.matrix .medium-skip-4-5 { margin-left: 80%; }
.matrix .medium-span-1-6 { width: 16.66666667%; }
.matrix .medium-skip-1-6 { margin-left: 16.66666667%; }
.matrix .medium-span-2-6 { width: 33.33333333%; }
.matrix .medium-skip-2-6 { margin-left: 33.33333333%; }
.matrix .medium-span-3-6 { width: 50%; }
.matrix .medium-skip-3-6 { margin-left: 50%; }
.matrix .medium-span-4-6 { width: 66.66666667%; }
.matrix .medium-skip-4-6 { margin-left: 66.66666667%; }
.matrix .medium-span-5-6 { width: 83.33333333%; }
.matrix .medium-skip-5-6 { margin-left: 83.33333333%; }
.matrix .medium-span-1-7 { width: 14.28571429%; }
.matrix .medium-skip-1-7 { margin-left: 14.28571429%; }
.matrix .medium-span-2-7 { width: 28.57142857%; }
.matrix .medium-skip-2-7 { margin-left: 28.57142857%; }
.matrix .medium-span-3-7 { width: 42.85714286%; }
.matrix .medium-skip-3-7 { margin-left: 42.85714286%; }
.matrix .medium-span-4-7 { width: 57.14285714%; }
.matrix .medium-skip-4-7 { margin-left: 57.14285714%; }
.matrix .medium-span-5-7 { width: 71.42857143%; }
.matrix .medium-skip-5-7 { margin-left: 71.42857143%; }
.matrix .medium-span-6-7 { width: 85.71428571%; }
.matrix .medium-skip-6-7 { margin-left: 85.71428571%; }
.matrix .medium-span-1-8 { width: 12.5%; }
.matrix .medium-skip-1-8 { margin-left: 12.5%; }
.matrix .medium-span-2-8 { width: 25%; }
.matrix .medium-skip-2-8 { margin-left: 25%; }
.matrix .medium-span-3-8 { width: 37.5%; }
.matrix .medium-skip-3-8 { margin-left: 37.5%; }
.matrix .medium-span-4-8 { width: 50%; }
.matrix .medium-skip-4-8 { margin-left: 50%; }
.matrix .medium-span-5-8 { width: 62.5%; }
.matrix .medium-skip-5-8 { margin-left: 62.5%; }
.matrix .medium-span-6-8 { width: 75%; }
.matrix .medium-skip-6-8 { margin-left: 75%; }
.matrix .medium-span-7-8 { width: 87.5%; }
.matrix .medium-skip-7-8 { margin-left: 87.5%; }
.matrix .medium-span-1-9 { width: 11.11111111%; }
.matrix .medium-skip-1-9 { margin-left: 11.11111111%; }
.matrix .medium-span-2-9 { width: 22.22222222%; }
.matrix .medium-skip-2-9 { margin-left: 22.22222222%; }
.matrix .medium-span-3-9 { width: 33.33333333%; }
.matrix .medium-skip-3-9 { margin-left: 33.33333333%; }
.matrix .medium-span-4-9 { width: 44.44444444%; }
.matrix .medium-skip-4-9 { margin-left: 44.44444444%; }
.matrix .medium-span-5-9 { width: 55.55555556%; }
.matrix .medium-skip-5-9 { margin-left: 55.55555556%; }
.matrix .medium-span-6-9 { width: 66.66666667%; }
.matrix .medium-skip-6-9 { margin-left: 66.66666667%; }
.matrix .medium-span-7-9 { width: 77.77777778%; }
.matrix .medium-skip-7-9 { margin-left: 77.77777778%; }
.matrix .medium-span-8-9 { width: 88.88888889%; }
.matrix .medium-skip-8-9 { margin-left: 88.88888889%; }
.matrix .medium-span-1-10 { width: 10%; }
.matrix .medium-skip-1-10 { margin-left: 10%; }
.matrix .medium-span-2-10 { width: 20%; }
.matrix .medium-skip-2-10 { margin-left: 20%; }
.matrix .medium-span-3-10 { width: 30%; }
.matrix .medium-skip-3-10 { margin-left: 30%; }
.matrix .medium-span-4-10 { width: 40%; }
.matrix .medium-skip-4-10 { margin-left: 40%; }
.matrix .medium-span-5-10 { width: 50%; }
.matrix .medium-skip-5-10 { margin-left: 50%; }
.matrix .medium-span-6-10 { width: 60%; }
.matrix .medium-skip-6-10 { margin-left: 60%; }
.matrix .medium-span-7-10 { width: 70%; }
.matrix .medium-skip-7-10 { margin-left: 70%; }
.matrix .medium-span-8-10 { width: 80%; }
.matrix .medium-skip-8-10 { margin-left: 80%; }
.matrix .medium-span-9-10 { width: 90%; }
.matrix .medium-skip-9-10 { margin-left: 90%; }
.matrix .medium-span-1-11 { width: 9.09090909%; }
.matrix .medium-skip-1-11 { margin-left: 9.09090909%; }
.matrix .medium-span-2-11 { width: 18.18181818%; }
.matrix .medium-skip-2-11 { margin-left: 18.18181818%; }
.matrix .medium-span-3-11 { width: 27.27272727%; }
.matrix .medium-skip-3-11 { margin-left: 27.27272727%; }
.matrix .medium-span-4-11 { width: 36.36363636%; }
.matrix .medium-skip-4-11 { margin-left: 36.36363636%; }
.matrix .medium-span-5-11 { width: 45.45454545%; }
.matrix .medium-skip-5-11 { margin-left: 45.45454545%; }
.matrix .medium-span-6-11 { width: 54.54545455%; }
.matrix .medium-skip-6-11 { margin-left: 54.54545455%; }
.matrix .medium-span-7-11 { width: 63.63636364%; }
.matrix .medium-skip-7-11 { margin-left: 63.63636364%; }
.matrix .medium-span-8-11 { width: 72.72727273%; }
.matrix .medium-skip-8-11 { margin-left: 72.72727273%; }
.matrix .medium-span-9-11 { width: 81.81818182%; }
.matrix .medium-skip-9-11 { margin-left: 81.81818182%; }
.matrix .medium-span-10-11 { width: 90.90909091%; }
.matrix .medium-skip-10-11 { margin-left: 90.90909091%; }
.matrix .medium-span-1-12 { width: 8.33333333%; }
.matrix .medium-skip-1-12 { margin-left: 8.33333333%; }
.matrix .medium-span-2-12 { width: 16.66666667%; }
.matrix .medium-skip-2-12 { margin-left: 16.66666667%; }
.matrix .medium-span-3-12 { width: 25%; }
.matrix .medium-skip-3-12 { margin-left: 25%; }
.matrix .medium-span-4-12 { width: 33.33333333%; }
.matrix .medium-skip-4-12 { margin-left: 33.33333333%; }
.matrix .medium-span-5-12 { width: 41.66666667%; }
.matrix .medium-skip-5-12 { margin-left: 41.66666667%; }
.matrix .medium-span-6-12 { width: 50%; }
.matrix .medium-skip-6-12 { margin-left: 50%; }
.matrix .medium-span-7-12 { width: 58.33333333%; }
.matrix .medium-skip-7-12 { margin-left: 58.33333333%; }
.matrix .medium-span-8-12 { width: 66.66666667%; }
.matrix .medium-skip-8-12 { margin-left: 66.66666667%; }
.matrix .medium-span-9-12 { width: 75%; }
.matrix .medium-skip-9-12 { margin-left: 75%; }
.matrix .medium-span-10-12 { width: 83.33333333%; }
.matrix .medium-skip-10-12 { margin-left: 83.33333333%; }
.matrix .medium-span-11-12 { width: 91.66666667%; }
.matrix .medium-skip-11-12 { margin-left: 91.66666667%; }
.matrix .medium-align-left { text-align: left; }
.matrix .medium-align-center { text-align: center; }
.matrix .medium-align-right { text-align: right; }
.matrix .medium-hidden { display: none; }
.matrix .medium-display-none { display: none; }
.matrix .medium-display-block { display: block; }
.matrix .medium-display-inline { display: inline; }
.matrix .medium-display-inline-block { display: inline-block; }
.matrix .medium-shown { display: block; }
.matrix .medium-visible { display: block; }
.matrix .medium-inline { display: inline; }
.matrix .medium-inline-block { display: inline-block; }
.matrix .medium-float-left { float: left; }
.matrix .medium-float-right { float: right; }
.matrix .medium-float-none { float: none; }
.matrix .medium-unpad-left[class*='span-'] { padding-left: 0; }
.matrix .medium-unpad-right[class*='span-'] { padding-right: 0; }

.matrix .medium-unpad-both[class*='span-'] {
  padding-left: 0;
  padding-right: 0;
}

.matrix .medium-pad-left[class*='span-'] { padding-left: 10px; }
.matrix .medium-pad-right[class*='span-'] { padding-right: 10px; }

.matrix .medium-pad-both[class*='span-'] {
  padding-left: 10px;
  padding-right: 10px;
}

.matrix .medium-reverse[class*='span-'] { float: right; }
.matrix .medium-unreverse[class*='span-'] { float: left; }
.matrix .large-span-auto { width: auto; }
.matrix .large-span-0 { width: 0; }
.matrix .large-skip-0 { margin-left: 0; }
.matrix .large-span-1 { width: 100%; }
.matrix .large-skip-1 { margin-left: 100%; }
.matrix .large-fill { width: 100%; }
.matrix .large-span-1-2 { width: 50%; }
.matrix .large-skip-1-2 { margin-left: 50%; }
.matrix .large-span-1-3 { width: 33.33333333%; }
.matrix .large-skip-1-3 { margin-left: 33.33333333%; }
.matrix .large-span-2-3 { width: 66.66666667%; }
.matrix .large-skip-2-3 { margin-left: 66.66666667%; }
.matrix .large-span-1-4 { width: 25%; }
.matrix .large-skip-1-4 { margin-left: 25%; }
.matrix .large-span-2-4 { width: 50%; }
.matrix .large-skip-2-4 { margin-left: 50%; }
.matrix .large-span-3-4 { width: 75%; }
.matrix .large-skip-3-4 { margin-left: 75%; }
.matrix .large-span-1-5 { width: 20%; }
.matrix .large-skip-1-5 { margin-left: 20%; }
.matrix .large-span-2-5 { width: 40%; }
.matrix .large-skip-2-5 { margin-left: 40%; }
.matrix .large-span-3-5 { width: 60%; }
.matrix .large-skip-3-5 { margin-left: 60%; }
.matrix .large-span-4-5 { width: 80%; }
.matrix .large-skip-4-5 { margin-left: 80%; }
.matrix .large-span-1-6 { width: 16.66666667%; }
.matrix .large-skip-1-6 { margin-left: 16.66666667%; }
.matrix .large-span-2-6 { width: 33.33333333%; }
.matrix .large-skip-2-6 { margin-left: 33.33333333%; }
.matrix .large-span-3-6 { width: 50%; }
.matrix .large-skip-3-6 { margin-left: 50%; }
.matrix .large-span-4-6 { width: 66.66666667%; }
.matrix .large-skip-4-6 { margin-left: 66.66666667%; }
.matrix .large-span-5-6 { width: 83.33333333%; }
.matrix .large-skip-5-6 { margin-left: 83.33333333%; }
.matrix .large-span-1-7 { width: 14.28571429%; }
.matrix .large-skip-1-7 { margin-left: 14.28571429%; }
.matrix .large-span-2-7 { width: 28.57142857%; }
.matrix .large-skip-2-7 { margin-left: 28.57142857%; }
.matrix .large-span-3-7 { width: 42.85714286%; }
.matrix .large-skip-3-7 { margin-left: 42.85714286%; }
.matrix .large-span-4-7 { width: 57.14285714%; }
.matrix .large-skip-4-7 { margin-left: 57.14285714%; }
.matrix .large-span-5-7 { width: 71.42857143%; }
.matrix .large-skip-5-7 { margin-left: 71.42857143%; }
.matrix .large-span-6-7 { width: 85.71428571%; }
.matrix .large-skip-6-7 { margin-left: 85.71428571%; }
.matrix .large-span-1-8 { width: 12.5%; }
.matrix .large-skip-1-8 { margin-left: 12.5%; }
.matrix .large-span-2-8 { width: 25%; }
.matrix .large-skip-2-8 { margin-left: 25%; }
.matrix .large-span-3-8 { width: 37.5%; }
.matrix .large-skip-3-8 { margin-left: 37.5%; }
.matrix .large-span-4-8 { width: 50%; }
.matrix .large-skip-4-8 { margin-left: 50%; }
.matrix .large-span-5-8 { width: 62.5%; }
.matrix .large-skip-5-8 { margin-left: 62.5%; }
.matrix .large-span-6-8 { width: 75%; }
.matrix .large-skip-6-8 { margin-left: 75%; }
.matrix .large-span-7-8 { width: 87.5%; }
.matrix .large-skip-7-8 { margin-left: 87.5%; }
.matrix .large-span-1-9 { width: 11.11111111%; }
.matrix .large-skip-1-9 { margin-left: 11.11111111%; }
.matrix .large-span-2-9 { width: 22.22222222%; }
.matrix .large-skip-2-9 { margin-left: 22.22222222%; }
.matrix .large-span-3-9 { width: 33.33333333%; }
.matrix .large-skip-3-9 { margin-left: 33.33333333%; }
.matrix .large-span-4-9 { width: 44.44444444%; }
.matrix .large-skip-4-9 { margin-left: 44.44444444%; }
.matrix .large-span-5-9 { width: 55.55555556%; }
.matrix .large-skip-5-9 { margin-left: 55.55555556%; }
.matrix .large-span-6-9 { width: 66.66666667%; }
.matrix .large-skip-6-9 { margin-left: 66.66666667%; }
.matrix .large-span-7-9 { width: 77.77777778%; }
.matrix .large-skip-7-9 { margin-left: 77.77777778%; }
.matrix .large-span-8-9 { width: 88.88888889%; }
.matrix .large-skip-8-9 { margin-left: 88.88888889%; }
.matrix .large-span-1-10 { width: 10%; }
.matrix .large-skip-1-10 { margin-left: 10%; }
.matrix .large-span-2-10 { width: 20%; }
.matrix .large-skip-2-10 { margin-left: 20%; }
.matrix .large-span-3-10 { width: 30%; }
.matrix .large-skip-3-10 { margin-left: 30%; }
.matrix .large-span-4-10 { width: 40%; }
.matrix .large-skip-4-10 { margin-left: 40%; }
.matrix .large-span-5-10 { width: 50%; }
.matrix .large-skip-5-10 { margin-left: 50%; }
.matrix .large-span-6-10 { width: 60%; }
.matrix .large-skip-6-10 { margin-left: 60%; }
.matrix .large-span-7-10 { width: 70%; }
.matrix .large-skip-7-10 { margin-left: 70%; }
.matrix .large-span-8-10 { width: 80%; }
.matrix .large-skip-8-10 { margin-left: 80%; }
.matrix .large-span-9-10 { width: 90%; }
.matrix .large-skip-9-10 { margin-left: 90%; }
.matrix .large-span-1-11 { width: 9.09090909%; }
.matrix .large-skip-1-11 { margin-left: 9.09090909%; }
.matrix .large-span-2-11 { width: 18.18181818%; }
.matrix .large-skip-2-11 { margin-left: 18.18181818%; }
.matrix .large-span-3-11 { width: 27.27272727%; }
.matrix .large-skip-3-11 { margin-left: 27.27272727%; }
.matrix .large-span-4-11 { width: 36.36363636%; }
.matrix .large-skip-4-11 { margin-left: 36.36363636%; }
.matrix .large-span-5-11 { width: 45.45454545%; }
.matrix .large-skip-5-11 { margin-left: 45.45454545%; }
.matrix .large-span-6-11 { width: 54.54545455%; }
.matrix .large-skip-6-11 { margin-left: 54.54545455%; }
.matrix .large-span-7-11 { width: 63.63636364%; }
.matrix .large-skip-7-11 { margin-left: 63.63636364%; }
.matrix .large-span-8-11 { width: 72.72727273%; }
.matrix .large-skip-8-11 { margin-left: 72.72727273%; }
.matrix .large-span-9-11 { width: 81.81818182%; }
.matrix .large-skip-9-11 { margin-left: 81.81818182%; }
.matrix .large-span-10-11 { width: 90.90909091%; }
.matrix .large-skip-10-11 { margin-left: 90.90909091%; }
.matrix .large-span-1-12 { width: 8.33333333%; }
.matrix .large-skip-1-12 { margin-left: 8.33333333%; }
.matrix .large-span-2-12 { width: 16.66666667%; }
.matrix .large-skip-2-12 { margin-left: 16.66666667%; }
.matrix .large-span-3-12 { width: 25%; }
.matrix .large-skip-3-12 { margin-left: 25%; }
.matrix .large-span-4-12 { width: 33.33333333%; }
.matrix .large-skip-4-12 { margin-left: 33.33333333%; }
.matrix .large-span-5-12 { width: 41.66666667%; }
.matrix .large-skip-5-12 { margin-left: 41.66666667%; }
.matrix .large-span-6-12 { width: 50%; }
.matrix .large-skip-6-12 { margin-left: 50%; }
.matrix .large-span-7-12 { width: 58.33333333%; }
.matrix .large-skip-7-12 { margin-left: 58.33333333%; }
.matrix .large-span-8-12 { width: 66.66666667%; }
.matrix .large-skip-8-12 { margin-left: 66.66666667%; }
.matrix .large-span-9-12 { width: 75%; }
.matrix .large-skip-9-12 { margin-left: 75%; }
.matrix .large-span-10-12 { width: 83.33333333%; }
.matrix .large-skip-10-12 { margin-left: 83.33333333%; }
.matrix .large-span-11-12 { width: 91.66666667%; }
.matrix .large-skip-11-12 { margin-left: 91.66666667%; }
.matrix .large-align-left { text-align: left; }
.matrix .large-align-center { text-align: center; }
.matrix .large-align-right { text-align: right; }
.matrix .large-hidden { display: none; }
.matrix .large-display-none { display: none; }
.matrix .large-display-block { display: block; }
.matrix .large-display-inline { display: inline; }
.matrix .large-display-inline-block { display: inline-block; }
.matrix .large-shown { display: block; }
.matrix .large-visible { display: block; }
.matrix .large-inline { display: inline; }
.matrix .large-inline-block { display: inline-block; }
.matrix .large-float-left { float: left; }
.matrix .large-float-right { float: right; }
.matrix .large-float-none { float: none; }
.matrix .large-unpad-left[class*='span-'] { padding-left: 0; }
.matrix .large-unpad-right[class*='span-'] { padding-right: 0; }

.matrix .large-unpad-both[class*='span-'] {
  padding-left: 0;
  padding-right: 0;
}

.matrix .large-pad-left[class*='span-'] { padding-left: 10px; }
.matrix .large-pad-right[class*='span-'] { padding-right: 10px; }

.matrix .large-pad-both[class*='span-'] {
  padding-left: 10px;
  padding-right: 10px;
}

.matrix .large-reverse[class*='span-'] { float: right; }
.matrix .large-unreverse[class*='span-'] { float: left; }
.matrix.tiny-reverse [class*='span-'] { float: right; }
.matrix.xs-reverse [class*='span-'] { float: right; }
.matrix.small-reverse [class*='span-'] { float: right; }
.matrix.medium-reverse [class*='span-'] { float: right; }
.matrix.large-reverse [class*='span-'] { float: right; }
